<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="10" sm="12">
                    <ValidationProvider name="Fertilizer Products Name (En)" vid="fertilizer_products_name_en" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="fertilizer_products_name_en"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('dam-config.fertilizer_products_name')}} {{$t('dam-config.en')}}<span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="fertilizer_products_name_en"
                              v-model="type.fertilizer_products_name_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Fertilizer Products Name (Bn)" vid="fertilizer_products_name_bn" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="fertilizer_products_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('dam-config.fertilizer_products_name')}} {{$t('dam-config.bn')}}<span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="fertilizer_products_name_bn"
                              v-model="type.fertilizer_products_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Chemical Element" vid="chemical_element" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="chemical_element"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                           {{$t('dam-config.chemical_element')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="chemical_element"
                              v-model="type.chemical_element"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Chemical Signal" vid="chemical_signal" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="chemical_signal"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                           {{$t('dam-config.chemical_signal')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="chemical_signal"
                              v-model="type.chemical_signal"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { fertilizerNameStore, fertilizerNameUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  mixins: [commonAuth],
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      type: {
        chemical_signal: '',
        chemical_element: '',
        fertilizer_products_name_bn: '',
        fertilizer_products_name_en: ''
      },
      loading: false
    }
  },
  created () {
    // this.type.org_id = this.userCheck()
    if (this.id) {
      const tmp = this.gettypeData()
      this.type = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  methods: {
    gettypeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.loading = true
      if (this.type.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${fertilizerNameUpdate}/${this.id}`, this.type)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, fertilizerNameStore, this.type)
      }
      this.loading = false
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
